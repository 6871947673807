<template>
  <a
    v-if="item.banner"
    class="image"
    :href="'/blog/' + item.shop_page[0].url"
    :style="{
      background: 'url(' + item.banner.image_object_big + ')',
    }"
    target="_blank"
  ></a>
  <div class="info">
    <span>{{ dateFormat(item.created_at) }}</span>
    <div>
      <img src="@/assets/images/shop-like-gray.svg" />
      {{ item.count_likes }}
    </div>
    <div>
      <img src="@/assets/images/shop-views.svg" />
      {{ item.count_views }}
    </div>
    <div v-if="item.count_comments">
      <img src="@/assets/images/shop-comments.svg" />
      {{ item.count_comments }}
    </div>
    <div v-else>
      <img src="@/assets/images/shop-comments.svg" />
      0
    </div>
  </div>
  <a class="name" :href="'/blog/' + item.shop_page[0].url" target="_blank">
    {{ item.shop_page[0].title }}
  </a>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";

export default {
  mixins: [pageMixin],
  props: {
    item: Object,
  },
};
</script>
